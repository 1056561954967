import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { LanguageCode, adjustLanguageForDialects } from '@shure/shared/models';

import { AppLanguageListService } from './app-language-list.service';

const locale = 'locale';

@Injectable({ providedIn: 'root' })
export class LanguagesService {
	public static readonly systemDefaultCultureCode = 'en';

	constructor(
		private readonly translocoService: TranslocoService,
		private readonly appLanguageListService: AppLanguageListService
	) {}

	public setLocale(cultureCode: string): boolean {
		const storedLocale = window.localStorage.getItem(locale);

		// Only change if different value than stored
		if (storedLocale !== cultureCode) {
			// Store locale id in local storage:
			window.localStorage.setItem(locale, cultureCode);
			this.translocoService.setActiveLang(cultureCode);
			return true;
		}
		return false;
	}

	public getPreferredCultureCode(): string {
		const storedLocale = window.localStorage.getItem(locale);
		let newLanguage = '';
		const availableLanguages: LanguageCode[] = this.appLanguageListService.getLanguageList().map((l) => l.id);

		if (!storedLocale) {
			newLanguage = adjustLanguageForDialects(window.navigator.language, availableLanguages);
		} else {
			newLanguage = adjustLanguageForDialects(<string>storedLocale, availableLanguages);
		}

		if (newLanguage !== storedLocale) {
			this.setLocale(newLanguage);
		}
		this.translocoService.setActiveLang(newLanguage);

		return newLanguage;
	}
}

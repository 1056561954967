<prism-toolbar *transloco="let t" id="sh-header-toolbar">
	<span slot="header" id="sh-header-toolbar-header-span">
		<div id="sh-header-toolbar-left-content-div">
			@if (showSidenavToggleButton && isSmallDevice()) {
				<ng-container>
					<prism-icon
						id="sh-header-toolbar-hamburger-icon"
						(click)="onSidenavToggle()"
						icon="menu"
						class="sh-header-toolbar-menu-icon"
					></prism-icon>
				</ng-container>
			}
		</div>
	</span>
	<span slot="header" id="sh-header-toolbar-header-span"
		><span class="prism-headline-xs sh-header-toolbar-welcome-text" id="sh-header-toolbar-welcome-text-span">{{
			getHeader()
		}}</span>
		@if (breadCrumbs().length && showBreadCrumbs) {
			<sh-breadcrumbs
				[breadcrumbs]="breadCrumbs()"
				(breadcrumbClick)="breadCrumbRedirect($event)"
			></sh-breadcrumbs>
		}
	</span>
	<span slot="end" id="sh-header-toolbar-end-span">
		@if (showFeatureButton) {
			<prism-button
				class="prism-button"
				id="header-toolbar-feature-request-button"
				variant="standard"
				size="md"
				(click)="openFeatureRequestDialog()"
			>
				{{ t('cloud.shared.header.feature-request') }}
			</prism-button>
		}
		<ng-container #bellIconContainer></ng-container>

		<prism-menu>
			<prism-profile-image
				data-testid="profile-image"
				[imageSrc]="getImageUrl$ | async"
				size="small"
				[attr.first-name]="firstName"
				[attr.last-name]="lastName"
				slot="menu-trigger"
				id="sh-header-toolbar-profile-image"
			>
			</prism-profile-image>
			<prism-menu-item [routerLink]="'/account'" id="sh-header-toolbar-account-menu-item">{{
				t('cloud.shared.header.my-account')
			}}</prism-menu-item>
			@if (showNotifications) {
				<prism-menu-item
					(click)="navigateToNotificationsList()"
					id="sh-header-toolbar-message-center-menu-item"
					>{{ t('cloud.shared.notification.message-center') }}</prism-menu-item
				>
			}
			<prism-menu-item (click)="navigateToShureIdFaq()" id="sh-header-toolbar-faq-menu-item">{{
				t('cloud.shared.header.my-shure-id-faq')
			}}</prism-menu-item>
			<prism-menu-item (click)="logout()" id="sh-header-toolbar-sign-out-menu-item">{{
				t('cloud.shared.header.sign-out-shure-id')
			}}</prism-menu-item>
		</prism-menu></span
	>
</prism-toolbar>
